import {Component} from '@angular/core';
import {MockDataService} from '../../../services/mock-data.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import * as moment from 'moment';
import {
    ConnectionIndicatorConfig
} from '../../../components/connection-indicator/connection-indicator.component';
import {MeterConnectionConfig} from '../../../shared/constants/connection.constants';
import {
    MeterDataProviderService
} from '../../../services/data-provider/meter-data-provider.service';
import {share, tap} from 'rxjs';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {InternalMeterConnectionStatus} from '../../../shared/enums/meter-status.enum';
import {MeterDetailLabels, MeterDetailTexts} from '../../../shared/texts/detail/meter-detail.texts';


@Component({
    selector: 'app-meter-details',
    templateUrl: './meter-details.component.html',
    styleUrls: ['./meter-details.component.scss'],
})

export class MeterDetailsComponent extends BasePopover {
    protected readonly ViewState = ViewState;
    protected readonly InternalMeterConnectionStatus = InternalMeterConnectionStatus;

    readonly LABELS = MeterDetailLabels;
    readonly TEXTS = MeterDetailTexts;
    readonly connectionConfig: ConnectionIndicatorConfig = MeterConnectionConfig;

    infoVisible = false;

    today = moment().format('DD.MM.YYYY');
    searchDateDisplay = 'TT.MM.JJJJ';
    hasFeedinData = false;


    meterData$ = this.meterDataProviderService.combinedMeterData$
        .asObservable().pipe(
            share(),
            tap((data) => this.hasFeedinData = data.meterValues.feedin !== undefined)
        );

    dateSpecificMeterValues$ = this.meterDataProviderService.dateSpecificMeterData$
        .asObservable();


    constructor(
        private mockDataService: MockDataService,
        private application: ApplicationService,
        protected popoverRef: PopoverRef,
        private meterDataProviderService: MeterDataProviderService,
    ) {
        super(popoverRef);
    }


    /**
     * Determine if the meter is connected.
     */
    isMeterConnected(status: InternalMeterConnectionStatus): boolean {
        return status === InternalMeterConnectionStatus.CONNECTED;
    }


    /**
     * Determines the status message for the meter connection status.
     */
    determineMeterStatusMessage(status: InternalMeterConnectionStatus): string {
        switch (status) {
            case InternalMeterConnectionStatus.CONNECTED:
                return this.LABELS.CONNECTED;
            default:
                return this.LABELS.DISCONNECTED;
        }
    }


    /**
     * Requests new meter values from the meter data provider service.
     * @param event
     */
    onDateSelected(event: any) {
        const date = moment(event.target.valueAsNumber);
        const parsed = date.toDate();
        this.searchDateDisplay = date.format('DD.MM.YYYY');
        this.meterDataProviderService.requestMeterValuesForDate(parsed);
    }


    onDateSelectedNew(event: Date) {
        const date = moment(event);
        const parsed = date.toDate();
        this.searchDateDisplay = date.format('DD.MM.YYYY');
        this.meterDataProviderService.requestMeterValuesForDate(parsed);
    }


}
