export enum HouseholdComparisonDetailLabels {
    TITLE = 'Haushaltsvergleich',
    TAB_TOTAL = 'Gesamt',
    TAB_APPLIANCES = 'Geräte',
    FILTER_HOUSEHOLD_SECTION_TITLE = 'Mein Haushalt',
    FILTER_HOUSEHOLD_OCCUPANTS = 'Bewohneranzahl',
    FILTER_HOUSEHOLD_SPACE_HEATING = 'Heizung',
    FILTER_HOUSEHOLD_WATER_HEATING = 'Warmwasser',
    FILTER_HOUSEHOLD_PROPERTY = 'Wohnobjekt',
    FILTER_APPLIANCE_SECTION_TITLE = 'Besondere Verbraucher',
    FILTER_APPLIANCE_EV_CHARGER = 'Ladesäule',
    FILTER_APPLIANCE_POOL = 'Pool',
    FILTER_APPLIANCE_SAUNA = 'Sauna',
    FILTER_BUTTON_APPLY = 'Übernehmen',
    TIMEFRAME_SELECT_LAST_MONTH = 'letzter Monat',
    TIMEFRAME_SELECT_CURRENT_YEAR = 'aktuelles Jahr',
    TIMEFRAME_SELECT_LAST_YEAR = 'letztes Jahr',
    DISPLAY_BUTTON_ADJUST = 'Gruppe anpassen',
    DISPLAY_COMPARABLE_HOUSHOLDS_ALL = 'alle Haushalte',
    DISPLAY_COMPARABLE_HOUSEHOLDS = 'vergleichbare Haushalte',
    DISPLAY_COMPARABLE_HOUSEHOLD = 'vergleichbarer Haushalt',
    DISPLAY_COMPARABLE_HOUSEHOLD_EMPTY = 'keine vergleichbaren Haushalte',
    STATE_EMPTY = 'Zu den ausgewählten Filtern konnten keine Vergleichsdaten gefunden werden.',
    STATE_EMPTY_DETAIL = 'Derzeit sind keine vergleichbaren Haushalte verfügbar.',
    STATE_LOADING = 'Daten werden geladen...',
    STATE_ERROR = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
    UNIT_KWH = 'kWh',
    DEVICES_COMPARABLE_HOUSEHOLDS = 'Vergleichbare Haushalte',
    DEVICES_MY_CONSUMPTION = 'Mein Verbrauch',
    DEVICES_BREAKDOWN_TITLE = 'Aufgliederung:',
    DETAIL_BTN_ADJUST_SETTINGS = 'Einstellungen anpassen',
}
