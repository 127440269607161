import {Component, OnInit} from '@angular/core';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/data-provider/household-comparison-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {
    HouseholdComparisonDetailLabels
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.labels';


@Component({
    selector: 'app-household-comparison-total',
    templateUrl: './household-comparison-total.component.html',
    styleUrls: ['./household-comparison-total.component.scss']
})
export class HouseholdComparisonTotalComponent implements OnInit {
    readonly LABELS = HouseholdComparisonDetailLabels;
    protected readonly ViewState = ViewState;


    householdComparisonData$ =
        this.householdComparisonDataProvider.combinedClusterRankingData$;


    constructor(
        private householdComparisonDataProvider: HouseholdComparisonDataProviderService
    ) {
    }


    ngOnInit(): void {
    }

}
