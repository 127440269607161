<div class="inflate container">
    <ng-container *ngIf="combinedClusterRankData$ | async as combinedData">

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.LOADING">
            <div class="inflate center-contents">
                {{LABELS.STATE_LOADING}}
            </div>
        </ng-container>

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.ERROR">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="empty-state-img"></div>
                    <div class="empty-state-label">
                        {{LABELS.STATE_ERROR}}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.EMPTY">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="empty-state-img"></div>
                    <div class="empty-state-label">
                        {{LABELS.STATE_EMPTY_DETAIL}}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.SUCCESS">
            <div class="ranking-list">
                <h2>Rangliste</h2>

                <app-household-comparison-rank-row
                    *ngFor="let row of combinedData.householdRankData.rowConfigs"
                    [config]="row">
                </app-household-comparison-rank-row>

                <div class="rank-row">
                    <div class="label">
                        {{TEXTS.RANKING.MESSAGES.MEAN_CONSUMPTION}}
                        {{combinedData.combinedClusterRankData.averageConsumption | number:'1.0-0':'de-DE'}}
                        {{LABELS.UNIT_KWH}}
                        .
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>
</div>
