<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Vergleich - Statisch; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading ">{{TEXTS.TITLE}}</h3>
            <div class="tile-content">

                <div #p class="comparison-chart-container">
                    <div class="tile-chart-abs"
                         [chart]="chart">
                        <!--                         [hidden]="!diagramInitialized"-->
                    </div>
                </div>

                <div class="comparison-stats p-v-l">
                    <div>
                        <div class="font-regular date m-b-s">{{formerValues.name}}</div>
                        <div class="font-light value"
                             [ngClass]="{higher: formerValues.higher, lower: formerValues.lower}">
                            {{formerValues.empty ? '-' : formerValues.y}}
                            {{TEXTS.UNIT_CONSUMPTION}}
                        </div>
                    </div>
                    <div>
                        <div class="font-regular date m-b-s">{{currentValues.name.toUpperCase()}}</div>
                        <div class="font-light value"
                             [ngClass]="{higher: currentValues.higher, lower: currentValues.lower}">
                            {{currentValues.empty ? '-' : currentValues.y }}
                            {{TEXTS.UNIT_CONSUMPTION}}
                        </div>
                    </div>
                </div>

                <div class="center-contents comparison-summary">
                    <div *ngIf="trend.percent > 0 && trend.percent < 9999" class="font-light">
                        <strong>
                            {{ trend.percent }}
                            {{TEXTS.LABEL_FRAGMENT_PERCENT}}
                            {{ trend.direction > 0 ? TEXTS.LABEL_FRAGMENT_MORE : TEXTS.LABEL_FRAGMENT_LESS }}
                        </strong>
                        {{TEXTS.LABEL_FRAGMENT_THAN_IN}}
                        {{ trend.monthname }}
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
