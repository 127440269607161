<div id="container">
    <app-menu activeElement="settings"></app-menu>
    <div class="page">
        <div id="inner">
            <div class="page-card">
                <h1> {{LABELS.PAGE_TITLE}}</h1>

                <section>
                    <h2 class="extra-space">{{LABELS.SECTION_TITLE_CONNECTIONS}}</h2>
                    <div>
                        <h3>{{deviceIsPlug ? 'Ihr iONA PowerChecker' : isBox2 ? 'Ihre iONA Box 2.0' : 'Ihre iONA Box'}}</h3>
                        <div class="connection-container p-b-l p-t-s">
                            <div class="p-r-s">
                                <app-connection-indicator [value]="wifiConnectionQuality"
                                                          container_height="24px"
                                                          [disabled]="wifiConnected >= 0"
                                                          [config]="wifiConnectionConfig"
                                                          [ignoreValue]="lanConnected">
                                </app-connection-indicator>
                            </div>
                            <ng-container *ngIf="lanConnected else wifiConnection">
                                <div> {{LABELS.CONNECTION_LABEL_CONNECTED}}</div>
                            </ng-container>
                            <ng-template #wifiConnection>
                                <div>
                                    {{
                                    wifiConnected < 0
                                        ? LABELS.CONNECTION_LABEL_WIFI_CONNECTED
                                        : LABELS.CONNECTION_LABEL_WIFI_DISCONNECTED
                                    }}
                                </div>
                            </ng-template>
                        </div>

                        <h3>{{LABELS.TITLE_YOUR_SMART_METER}}</h3>
                        <div class="connection-container p-r-s p-b-l">
                            <div class="p-r-s">
                                <app-connection-indicator [value]="meterConnectionQuality"
                                                          [container_height]="'24px'"
                                                          [disabled]="meterStatus !== 'connected'"
                                                          [config]="meterConnectionConfig">
                                </app-connection-indicator>
                            </div>
                            <div>{{determineMeterStatusMessage()}}</div>
                        </div>

                        <ng-container *ngIf="pincode">
                            <h3>{{LABELS.TITLE_CURRENT_PIN}}</h3>
                            <div class="p-r-s">
                                {{pincode}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isEDGUser && userService.isEnviamUser() && pinEntryUnknownOrOptical">
                            <button class="iona-button orange m-t-l"
                                    [disabled]="currentBatteryState === 0"
                                    (click)="onPinEntryOpen()">
                                {{LABELS.BTN_LABEL_ENTER_METER_PIN}}
                            </button>
                        </ng-container>

                        <ng-container *ngIf="isEDGUser">
                            <h3 class="m-t-l">{{LABELS.TITLE_BATTERY_STATUS}}</h3>
                            <div class="p-t-s">
                                <app-battery-load [currentBatteryState]="currentBatteryState"
                                                  [isAvailable]="currentBatteryState > -1">
                                </app-battery-load>
                            </div>

                            <button class="iona-button orange m-t-x"
                                    [disabled]="currentBatteryState === 0"
                                    (click)="onEnergySaverOpen()">
                                {{LABELS.BTN_LABEL_ENERGY_SAVER}}
                            </button>
                        </ng-container>
                    </div>
                </section>

                <section>
                    <h2 class="extra-space">{{LABELS.SETTING_TITLE_ONLINE_MODE}}</h2>
                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="optin-checkbox">
                            <input #optInCb type="checkbox" name="checkbox" id="optin-checkbox"
                                   [checked]="config.optIn"
                                   (change)="setOptIn(optInCb.checked)">
                            {{LABELS.LABEL_ALLOW_ONLINE_MODE}}
                        </label>
                        <p>
                            Wenn Sie alle Funktionen von iONA nutzen möchten, können Sie hier für die Zukunft in den
                            Online-Modus wechseln. Damit geben Sie uns Ihre Einwilligung um auf die
                            Verbrauchsdaten {{deviceIsPlug ? 'Ihres Powercheckers' : isBox2 ? 'Ihrer iONA Box 2.0' : 'Ihrer Box'}} zugreifen zu dürfen.
                            Dies beinhaltet unter anderem ihren Stromverbrauch in Sekunden-Auflösung. Dieser Zugriff ist
                            notwendig, damit wir Ihnen den vollen Funktionsumfang - der in den Nutzungsbedingungen der
                            App beschrieben wird – anbieten können. Die weitere Verarbeitung dieser Daten wird in
                            unseren Datenschutzhinweisen und den folgenden Einwilligungserklärungen beschrieben. Sie
                            können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen indem Sie die
                            Option in den Einstellungen wieder deaktivieren.
                        </p>
                    </div>


                    <h2 class="extra-space">{{LABELS.SETTING_TITLE_BENCHMARKING}}</h2>
                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="hhc-optout-checkbox">
                            <input #hhcOptOutCb type="checkbox" name="checkbox" id="hhc-optout-checkbox"
                                   [checked]="config.householdComparisonOptOut"
                                   (change)="setHouseholdComparisonOptOut(hhcOptOutCb.checked)">
                            {{LABELS.LABEL_ALLOW_BENCHMARKING}}
                        </label>
                        <p>
                            Durch die Deaktivierung können Sie der Anonymisierung Ihres Verbrauchs- und
                            Haushaltsprofils zum Zweck des Haushaltsvergleichs widersprechen. Die Funktion
                            Haushaltsvergleich steht nach dem Widerspruch nicht mehr zur Verfügung.
                        </p>
                    </div>

                    <h2 class="extra-space">{{LABELS.SETTING_TITLE_OPT_IN_DATA}}</h2>
                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="data-optin-checkbox">
                            <input #dataOptInCheckbox type="checkbox" name="checkbox" id="data-optin-checkbox"
                                   [checked]="config.dataOptin"
                                   (change)="setDataOptinCheckBox(dataOptInCheckbox.checked)">
                            {{LABELS.LABEL_ALLOW_OPT_IN_DATA}}
                        </label>
                        <p>
                            Ich bin damit einverstanden, dass die Messwerte meiner Messeinrichtung und die freiwilligen Angaben in meinem Haushaltsprofil durch die envia Mitteldeutsche Energie AG dazu verwendet werden, dass Analysen zur Produkt-, Projekt-, und Prozessverbesserung (z.B. Lastprofil, Beschaffung, Stromverbrauchsmuster) sowie zu Presse- und Kommunikationszwecken erstellt werden. Diese Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie den Schieberegler zurückschieben.
                        </p>
                    </div>
                </section>

                <button class="iona-button orange" (click)="saveSettings()">
                    {{LABELS.BTN_LABEL_SAVE}}
                </button>
            </div>
        </div>
    </div>
</div>
