import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {TILE_TYPE, TileDef, TileService} from '../../services/tile.service';
import { InitializationService } from 'src/app/services/initialization.service';

@Component({
    selector: 'app-add-tile',
    templateUrl: './add-tile.component.html',
    styleUrls: ['./add-tile.component.scss']
})
export class AddTileComponent implements OnInit {

    currentTiles: TileDef[] = [];

    constructor(public popoverRef: PopoverRef,
                private tilesService: TileService,
                private initialization: InitializationService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
        this.initTileList();
    }

    ngOnInit() {
    }

    /**
     * Closes the overlay
     */
    close(): void {
        this.popoverRef.close(null);
    }


    /**
     * On Tile toggle event;
     * @param tile
     */
    onTileToggle(tile: TileDef) {
        if (tile.selected) {
            return;
        }
        if (tile.type === TILE_TYPE.MVP) {
            this.tilesService.toggleMVPTile(tile.id);
            return;
        }
        this.tilesService.toggleTile(tile);
        this.initTileList();
    }


    /**
     * Initializes the tile list by retrieving the current tile list from the tile-service.
     *
     * Then sort the tiles according to their addTile idx so they always have the same ordering
     * @private
     */
    private initTileList(): void {
        this.initialization.getWithCache().subscribe((data) => {
            let isVisionUser = false;
            if('product_name' in data){
                isVisionUser = data.product_name.toLowerCase().includes('vision');
            }

            const tiles = this.tilesService.getCurrentTiles(isVisionUser);
            const tilesCpy = JSON.parse(JSON.stringify(tiles));
            const sortedTiles = tilesCpy.sort((a, b) => {
                if (a.addIndex < b.addIndex) {
                    return -1;
                }
                if (a.addIndex > b.addIndex) {
                    return 1;
                }
                return 0;
            });
            this.currentTiles = sortedTiles;
            
        },
        (error) => {
            console.error(error);
          }
        )
    }


    /**
     * Overlay data init
     * @private
     */
    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close();
        });
    }

}


