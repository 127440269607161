import {Component, OnInit} from '@angular/core';
import {
    HouseholdComparisonDetailLabels
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.labels';
import {
    HouseholdComparisonDetailTexts
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.texts';
import {BehaviorSubject, Observable, share, tap} from 'rxjs';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/data-provider/household-comparison-data-provider.service';
import {ProfileService} from '../../../services/profile.service';
import {map, mergeMap} from 'rxjs/operators';
import {HouseholdAttributeIdentifierMapping} from '../../../lib/household.mapping';
import {HouseholdProfile} from '../../../shared/interfaces/profile-attributes.interfaces';
import {BenchmarkFilterPayloadProps} from '../../../shared/interfaces/benchmark.interfaces';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {ApplicationService} from '../../../services/application.service';


@Component({
    selector: 'app-household-comparison-filter-display',
    templateUrl: './household-comparison-filter-display.component.html',
    styleUrls: ['./household-comparison-filter-display.component.scss']
})
export class HouseholdComparisonFilterDisplayComponent implements OnInit {
    protected readonly ViewState = ViewState;
    readonly LABELS = HouseholdComparisonDetailLabels;
    readonly TEXTS = HouseholdComparisonDetailTexts;

    enterFormMode = new BehaviorSubject<boolean>(null);

    householdComparisonData$ =
        this.householdComparisonDataProvider.combinedClusterRankingData$.pipe(
            share()
        );

    currentFilter$: Observable<Array<HouseholdComparisonFilterDisplayItem>> =
        this.householdComparisonDataProvider.filterChanged$.pipe(
            mergeMap(props => {
                return this.profileService.getAttributesWithCache().pipe(
                    map((profile) => ({props, profile}))
                );
            }),
            map(({props, profile}) =>
                this.createFilterDisplayItems(props, profile)
            )
        );

    private readonly mapping: Array<HouseholdComparisonFilterDisplayItem> = [
        {
            propKey: 'occupants',
            profileCategory: 'Attributes',
            profileKey: 'Occupants',
            title: 'Bewohneranzahl',
            wording: 'Person'
        },
        {
            propKey: 'space_heating',
            profileCategory: 'Attributes',
            profileKey: 'SpaceHeating',
            title: 'Heizung',
            wording: 'Heizung',
        },
        {
            propKey: 'water_heating',
            profileCategory: 'Attributes',
            profileKey: 'WaterHeating',
            title: 'Warmwasser',
            wording: 'Warmwasser',
        },
        {
            propKey: 'property_type',
            profileCategory: 'Attributes',
            profileKey: 'PropertyType',
            title: 'Wohnobjekt',
            wording: 'Gebäudetyp',
        },
        {
            propKey: 'ev_charger',
            profileCategory: 'Appliances',
            profileKey: 'A.21',
            title: 'Ladesäule',
            wording: 'Elektroauto',
        },
        {
            propKey: 'swimming_pool',
            profileCategory: 'Appliances',
            profileKey: 'A.22',
            title: 'Pool',
            wording: 'Pool',
        },
        {
            propKey: 'sauna',
            profileCategory: 'Appliances',
            profileKey: 'A.23',
            title: 'Sauna',
            wording: 'Sauna',
        }
    ];


    constructor(
        private profileService: ProfileService,
        private householdComparisonDataProvider: HouseholdComparisonDataProviderService,
        public application: ApplicationService,
    ) {
    }


    ngOnInit(): void {
    }


    onEnterFormMode(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        this.enterFormMode.next(true);
    }


    determineFilterIcon(propKey: string): string {
        return `url("/assets/img/icons/household/${propKey}.png")`;
    }


    /**
     * Creates the filter display items for the current filter.
     * @param props
     * @param profile
     * @private
     */
    private createFilterDisplayItems(
        props: BenchmarkFilterPayloadProps,
        profile: HouseholdProfile
    ): Array<HouseholdComparisonFilterDisplayItem> {
        const positiveFilteredKeys = Object.keys(props).filter(key =>
            props[key] !== undefined
        );
        const copiedMapping = JSON.parse(JSON.stringify(this.mapping));
        return positiveFilteredKeys.map(key => {
            const mapping = copiedMapping.find(el => el.propKey === key);
            const value = profile[mapping.profileCategory][mapping.profileKey];
            mapping.value = value;
            const mappingType = typeof value === 'string' ? 'string' : 'number';
            if (mappingType === 'string') {
                mapping.wording = HouseholdAttributeIdentifierMapping
                    .find(el => el.key === value).value;
                mapping.profileKey = value;
            } else if (mappingType === 'number') {
                mapping.wording = `Anzahl: ${value}`;
            }
            return mapping;
        });
    }

}


interface HouseholdComparisonFilterDisplayItem {
    propKey: string;
    profileCategory: string;
    profileKey: string;
    wording: string;
    title: string;
    value?: any;
    type?: 'numerical' | 'categorical';
}
