export enum TodayTileLabels {
    TITLE = 'Heute',
    UNIT_MONEY = '€',
    UNIT_CONSUMPTION = 'kWh',
    LABEL_COMPARISON_TODAY = 'HEUTE',
    MORE = 'mehr',
    LESS = 'weniger',
    LABEL_FRAGMENT_LAST = 'als letzten',
    LABEL_FRAGMENT_IDENTICAL = 'identisch',
    LABEL_FRAGMENT_TO_LAST = 'zum letzten',
    LABEL_FRAGMENT_PERCENT = '%',
    LABEL_LOADING = 'Daten werden noch geladen...',
    LABEL_ERROR_STATE = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
}
