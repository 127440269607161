import {Component, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {TodayTileLabels} from '../../../shared/texts/tiles/today-tile.labels';
import {TodayDataProviderService} from '../../../services/data-provider/today-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';


@Component({
    selector: 'app-today-tile',
    templateUrl: './today-tile.component.html',
    styleUrls: ['./today-tile.component.scss'],
    viewProviders: [],
    providers: [Globals]
})
export class TodayTileComponent extends BaseComponent implements OnInit, OnDestroy {
    private readonly type: TILE_TYPE = TILE_TYPE.TODAY;
    protected readonly LABELS = TodayTileLabels;
    protected readonly ViewState = ViewState;

    todayTileData$ = this.todayDataProvider.todayTileData$;


    constructor(
        private globals: Globals,
        private analytics: TrackAnalyticsService,
        private tiles: TileService,
        private todayDataProvider: TodayDataProviderService
    ) {
        super();
    }


    ngOnInit() {
        this.todayDataProvider.startContinuousTileDataUpdate();
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        this.todayDataProvider.killTileSub();
    }


    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /*
     * Analytic stuff
     * =============================================================================================
     */
    private detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Today',
            }
        });
    }


    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Today-Details'
            }
        });
    }
}
