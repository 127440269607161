export enum MeterDetailLabels {
    TITLE = 'Zählerstand',
    UNIT = 'kWh',
    METER_NUMBER = 'Zählernummer',
    VALUE_FEEDIN = 'Zählerstand Einspeisung',
    VALUE_CONSUMPTION = 'Zählerstand Bezug',
    CONNECTED= 'verbunden',
    DISCONNECTED= 'nicht verbunden',
}


export const MeterDetailTexts = {
    TILE_INFO: {
        TITLE: 'Zählerstand: Was ist das?',
        PARAGRAPH: 'Der Weg in den Keller bleibt Ihnen erspart: Rufen Sie jederzeit bequem Ihren aktuellen Zählerstand ab. Wetten, dass iONA sich an jeden Zählerstand erinnern kann? Probieren Sie es aus!'
    },
    METER_VALUE_DATE: {
        TITLE: 'Zählerstand zum Stichtag',
        PARAGRAPH: 'Hier können Sie Ihren Zählerstand zu einem bestimmten Stichtag abrufen. Einfach Wunschdatum eingeben und Zählerstand ablesen.'

    }
};
