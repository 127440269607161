export enum SettingsLabels {
    PAGE_TITLE = 'Einstellungen',
    SECTION_TITLE_CONNECTIONS = 'Verbindungen',
    CONNECTION_LABEL_CONNECTED = 'verbunden',
    CONNECTION_LABEL_WIFI_CONNECTED = '(W-)LAN verbunden',
    CONNECTION_LABEL_WIFI_DISCONNECTED = '(W-)LAN nicht verbunden',
    TITLE_YOUR_SMART_METER = 'Ihr Stromzähler',
    TITLE_CURRENT_PIN = 'Aktuelle Zähler-PIN',
    BTN_LABEL_ENTER_METER_PIN = 'Zähler-PIN eingeben',
    TITLE_BATTERY_STATUS = 'Batteriestatus',
    BTN_LABEL_ENERGY_SAVER = 'Energiesparen',
    SETTING_TITLE_ONLINE_MODE = 'Online-Modus',
    LABEL_ALLOW_ONLINE_MODE = 'Online-Modus erlauben',
    SETTING_TITLE_BENCHMARKING = 'Anonyme Datenverarbeitung',
    LABEL_ALLOW_BENCHMARKING = 'Anonyme Datenverarbeitung erlauben',
    SETTING_TITLE_OPT_IN_DATA = 'Opt-In Datenverarbeitung',
    LABEL_ALLOW_OPT_IN_DATA = 'Opt-In Datenverarbeitung erlauben',
    BTN_LABEL_SAVE = 'Einstellungen speichern',
}
