<div class="diagram-navigation">
    <button class="iona-icon-only-button prev"
            (click)="positionBack()" [disabled]="interactionDisabled">
    </button>
    <div class="current-timeframe">{{currentDataDate}}</div>
    <button class="iona-icon-only-button next"
            (click)="positionForward()" [disabled]="interactionDisabled || timeframeOffset === 0">
    </button>
</div>

<ng-container *ngIf="appliancesDetailData$ | async as detailData">
    <ng-container [ngSwitch]="detailData.viewState">

        <ng-container *ngSwitchCase="ViewState.INITIAL">
            <div class="inflate center-contents">
                {{TEXTS.LOADING}}
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="ViewState.ERROR">
            <div class="inflate center-contents">
                {{TEXTS.ERROR}}
            </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <div class="appliances-details-diagram-container">
                <div class="appliance-diagram-new" *ngIf="isDataAvailable; else emptyState">
                    <app-appliance-chart
                        [isDetail]="true"
                        [series]="detailData.series"
                        [viewState]="detailData.viewState">
                    </app-appliance-chart>
                </div>
                <ng-template #emptyState>
                    <div class="animation-wrapper">
                        <div class="animation-container"
                             lottie [options]="lottieConfig">
                        </div>
                    </div>
                </ng-template>
            </div>

            <ul class="appliances-list">
                <li *ngFor="let category of detailData.categories"
                    [style.border-color]="category.color"
                    (click)="toggleAccordion(category)">
                    <div class="li-content-wrapper" [style.background]="category.color">
                        <div class="list-icon-wrapper">
                            <div class="list-icon"
                                 [style.mask-image]="determineCategoryImagePath(category.name.toLowerCase())"
                                 [style.-webkit-mask-image]="determineCategoryImagePath(category.name.toLowerCase())"
                                 [style.background-color]="'#fff'">
                            </div>
                            <div *ngIf="category.categoryProfileIncompleteOrRetraining"
                                 class="attention-indicator">
                            </div>
                        </div>
                        <div class="grow-space font-bold">
                            {{ translateAppliance(category.name) }}
                        </div>
                        <div class="font-bold">{{ category.kwh }} {{LABELS.UNIT_CONSUMPTION}}</div>
                        <div *ngIf="!isVisionUser" class="cost font-bold">{{ category.cost }}</div>
                        <div class="accordion-indicator">
                            <i [ngClass]="{rotated: category.accordionOpen}"></i>
                        </div>
                    </div>
                    <!-- ACCORDION CONTENT-->
                    <div class="accordion" [hidden]="!category.accordionOpen">
                        <div class="category-list">
                            <div *ngFor="let item of category.appliances">
                                <div class="sub-icon"
                                     [style.mask-image]="determineApplianceCategoryItemPath(item.icon)"
                                     [style.-webkit-mask-image]="determineApplianceCategoryItemPath(item.icon)">
                                </div>
                                <div
                                    class="sub-name font-bold">{{translateAppliance(item.appliance_instance_id)}}</div>
                                <div class="sub-value">
                                    {{LABELS.UNIT_CONSUMPTION}}
                                    <br>
                                    {{item.energy_ws}}
                                </div>
                                <div class="sub-value">
                                    {{LABELS.UNIT_COSTS}}
                                    <br>
                                    {{item.cost}}
                                </div>
                                <div class="spacer"></div>
                            </div>
                        </div>
                        <p>
                            {{category.description}}
                        </p>
                    </div>
                </li>
            </ul>
        </ng-container>

    </ng-container>
</ng-container>

<ng-container *ngIf="currentNilmPhaseAllowsRetraining$ | async as retrainingAllowed">
    <ng-container *ngIf="retrainingAllowed">
        <div class="center-contents m-t-l">
            <button class="iona-button orange"
                    (click)="enterRetraining$.next(true)"
            >{{LABELS.BTN_RETRAINING}}</button>
        </div>
    </ng-container>
</ng-container>
