export interface AppliancesRetrainingCategories {
    alwaysOn: AppliancesRetrainingCategory;
    refrigeration: AppliancesRetrainingCategory;
    lighting: AppliancesRetrainingCategory;
    entertainment: AppliancesRetrainingCategory;
    laundry: AppliancesRetrainingCategory;
    oven: AppliancesRetrainingCategory;
    spaceHeating: AppliancesRetrainingCategory;
    waterHeating: AppliancesRetrainingCategory;
    electricVehicle: AppliancesRetrainingCategory;
}


export interface AppliancesRetrainingCategory {
    label: string;
    appliances: ApplianceMapping[];
    isInRetraining: boolean;
    retrainingProgressPercentage: number;
    iconPath: string;
    disabled: boolean;
}


export interface ApplianceMapping {
    name: string;
    label: string;
}


export interface AppliancesRetrainingCategoriesListItem {
    category: string;
    data: AppliancesRetrainingCategory;
}


export const initialAppliancesRetrainingCategories: AppliancesRetrainingCategories = {
    alwaysOn: {
        label: 'Grundlast',
        appliances: [],
        isInRetraining: false,
        iconPath: '/assets/img/graphics/appliances/alwayson.png',
        retrainingProgressPercentage: 0,
        disabled: false,
    },
    refrigeration: {
        label: 'Kühlung',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/refrigeration.png',
        disabled: false,
    },
    lighting: {
        label: 'Beleuchtung',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/lighting.png',
        disabled: false,
    },
    entertainment: {
        label: 'Unterhaltung',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/entertainment.png',
        disabled: false,
    },
    laundry: {
        label: 'Reinigen & Trocknen',
        appliances: [
            {
                name: 'dishWasher',
                label: 'Geschirrspüler',
            },
            {
                name: 'washingMachine',
                label: 'Waschmaschine',
            },
            {
                name: 'dryer',
                label: 'Trockner',
            }
        ],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/laundry.png',
        disabled: false,
    },
    oven: {
        label: 'Kochen',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/icons/household/A.04.png',
        disabled: false,
    },
    spaceHeating: {
        label: 'Heizung & Kühlung',
        appliances: [
            {
                name: 'heatPumps',
                label: 'Wärmepumpe',
            },
            {
                name: 'airConditioning',
                label: 'Klimaanlage',
            }
        ],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/spaceheating.png',
        disabled: false,
    },
    waterHeating: {
        label: 'Warmwasser',
        appliances: [
            {
                name: 'waterBoiler',
                label: 'Wasserkocher',
            },
            {
                name: 'electricShower',
                label: 'Elektrische Dusche',
            },
            {
                name: 'flowHeater',
                label: 'Durchlauferhitzer',
            },
        ],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/waterheating.png',
        disabled: false,
    },
    electricVehicle: {
        label: 'Elektrofahrzeug',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/icons/household/A.21.png',
        disabled: false,
    }
};
