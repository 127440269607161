export const AppliancesDetailTexts = {
    INFO: {
        TITLE: 'Geräte: Was ist das?',
        PARAGRAPHS: [
            'Gibt es einen Stromfresser in Ihrem Haushalt? Finden Sie es mit iONA heraus. Erkennen Sie, wie viel Ihre einzelnen Geräte verbrauchen und welche Kosten dabei entstehen. Wagen Sie einen Blick in die Vergangenheit mit der zweiten Detailansicht.',
            'Der Algorithmus nutzt die angegebenen Profil- und Geräteinformationen, um im Gesamtverbrauch die typischen Verläufe einzelner Verbraucher zu identifizieren. Insbesondere größere Verbraucher, wie Waschmaschinen, Trockner, Backöfen oder Spülmaschinen können sehr gut identifiziert werden. Darüber hinaus werden statistische Daten herangezogen, um den Stromverbrauch einzelner Kategorien zu ermitteln.'
        ]
    },
    INFO_ALT: {
        TITLE: 'Geräte neu anlernen',
        PARAGRAPHS: [
            'Die Geräteerkennung versucht auf Basis Ihrer Profilinformationen größere Verbraucher in Ihrem Haushalt zu erkennen und durch Ergänzung mit statistischen Daten den Stromverbrauch den verschiedenen Kategorien zuzuordnen. Sollten Sie größere Veränderungen vorgenommen haben, wie z.B. den Austausch einer Waschmaschine, oder generell mit der Zuordnung zu einer Kategorie unzufrieden sein, dann haben Sie die Möglichkeit, die Lernphase der Geräterkennung je Kategorie neu zu starten. Der Algorithmus benötigt ca. 30 Tage bis Ihnen die aktualisierten Ergebnisse zur Verfügung stehen. Bitte prüfen Sie vorab Ihr Haushaltsprofil auf Aktualität. Den Fortschritt der Lernphase können Sie jeder Zeit hier einsehen.'
        ]
    },
    BANNER_UPDATE: {
        TEXT: 'Bitte prüfen Sie Ihr Haushaltsprofil auf Aktualität.',
        BUTTON_LABEL: 'Mein Haushaltsprofil prüfen'
    },
    BANNER_RETRAINING: {
        TEXT: 'Aktuell werden Geräte neu angelernt. Mehr zum aktuellen Status erfahren Sie hier.'
    },
    ERROR: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
    LOADING: 'Daten werden geladen...',
    RETRAINING_INFO_PARAGRAPH: 'Sie haben neue Geräte oder wünschen eine Aktualisierung der Gerätekategorien? Dann haben Sie die Möglichkeit, die Lernphase der Geräterkennung neu zu starten. Nach ca. 30 Tagen stehen Ihnen die aktualisierten Ergebnisse zur Verfügung. Bitte prüfen Sie vorab Ihr Haushaltsprofil auf Aktualität.',
    RETRAINING_POPOVER_INFO: 'Die Anlernphase wird über Nacht neu gestartet und der Fortschritt anschließend hier angezeigt.',
};
