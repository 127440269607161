import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    HouseholdComparisonDetailLabels
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.labels';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/data-provider/household-comparison-data-provider.service';
import {translateApplianceLowercase} from '../../../tiles/appliances/appliances.utils';


@Component({
    selector: 'app-household-comparison-appliances',
    templateUrl: './household-comparison-appliances.component.html',
    styleUrls: ['./household-comparison-appliances.component.scss']
})
export class HouseholdComparisonAppliancesComponent implements OnInit, OnDestroy {
    readonly LABELS = HouseholdComparisonDetailLabels;

    protected readonly ViewState = ViewState;
    protected readonly translateApplianceLowercase = translateApplianceLowercase;

    householdDeviceCategoriesData$ =
        this.householdComparisonDataProvider.deviceCategoriesData$;


    constructor(
        private householdComparisonDataProvider: HouseholdComparisonDataProviderService
    ) {
    }


    ngOnInit(): void {
        this.householdComparisonDataProvider.getDeviceCategoriesData();
        this.householdComparisonDataProvider.setDeviceCategoriesModeIsActive(true);
    }


    ngOnDestroy(): void {
        this.householdComparisonDataProvider.setDeviceCategoriesModeIsActive(false);
    }


    /**
     * Returns the url for the appliance icon.
     * @param name
     */
    getApplianceIconUrl(name: string): string {
        if (name === 'vehicle') {
            name = 'electricvehicle';
        }
        return `url("assets/img/graphics/appliances/${name}.png")`;
    }

}
