import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../services/user.service';
import {getProviderMenuName, getProviderSettingsLink} from '../../lib/ProviderUtil';
import {ApplicationService} from '../../services/application.service';
import {BaseComponent} from '../../classes/base-component';
import {AccountsTexts} from '../../shared/texts/accounts.texts';
import {LocalOptInService} from '../../services/local-opt-in.service';

@Component({
    selector: 'iona-app',
    templateUrl: './account.component.html',
    viewProviders: []
})

export class AccountComponent extends BaseComponent implements OnInit {
    readonly TEXTS = AccountsTexts;

    providerMenuName = '';
    providerSettingsLink = '';

    mainTitle = 'Mein iONA';
    buttonTitle = 'Mein iONA';

    isErnaUser = false;

    constructor(private titleService: Title,
                private userService: UserService,
                public application: ApplicationService,
                private optInService: LocalOptInService) {
        super();
    }

    ngOnInit() {
        if (!this.application.isDemoMode()) {
            this.mainTitle = getProviderMenuName(this.userService.getActiveUserProvider());
        }

        this.optInService.checkStatus();

        this.buttonTitle = this.mainTitle;
        this.providerMenuName = this.mainTitle;

        this.providerSettingsLink = getProviderSettingsLink(this.userService.getActiveUserProvider());
        this.titleService.setTitle(`${this.providerMenuName} | iONA`);
        this.isErnaUser = this.userService.isEDGUser();
    }

    openLink() {
        window.open(this.providerSettingsLink, '');
    }
}
