import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SingleDayComparisonData} from '../../../shared/interfaces/today-tile-data.interfaces';
import {TodayTileLabels} from '../../../shared/texts/tiles/today-tile.labels';
import { InitializationService } from 'src/app/services/initialization.service';


@Component({
    selector: 'app-today-tile-stats',
    templateUrl: './today-tile-stats.component.html',
    styleUrls: ['./today-tile-stats.component.scss']
})
export class TodayTileStatsComponent implements OnChanges, OnInit {
    protected readonly TEXTS = TodayTileLabels;

    @Input() today: SingleDayComparisonData;
    @Input() comparisonDate: SingleDayComparisonData;

    isVisionUser = true;


    constructor(private initialization: InitializationService) {
    }

    ngOnInit(): void {
        this.checkIsVisionUser();
    }


    ngOnChanges(changes: SimpleChanges) {
    }


    extractRealValue(value: string): string {
        return value.split(',')[0] + ',';
    }


    extractDecimalValue(value: string): string {
        return value.split(',')[1];
    }


    checkIsVisionUser(): void {
        this.initialization.getWithCache().subscribe((data) => {
            if('product_name' in data){
                this.isVisionUser = data.product_name.toLowerCase().includes('vision');
            }
        })
    }

    /**
     * Formats a numerical value
     * @param value
     */
    formatValue(value: number): string {
        if (value === 0.0) {
            return '-';
        }
        return value.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        );
    }

}
