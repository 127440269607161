<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">
                        {{LABELS.TITLE}}
                    </h1>

                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Heute"
                            (click)="infoVisible = !infoVisible">
                    </button>

                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
                <section>
                    <div class="today-content">

                        <ng-container *ngIf="todayDetailData$ | async as detailData">
                            <ng-container [ngSwitch]="detailData.viewState">
                                <ng-container *ngSwitchDefault>
                                    <div class="today-visualization grow-space">
                                        <div class="pie-wrapper">
                                            <div class="today-pie left {{detailData.leftState}}" [halfCircle]="'left'"
                                                 [style.transform]="'scale(' + detailData.trend.scale.left + ')'"></div>
                                            <div class="today-pie right {{detailData.rightState}}"
                                                 [halfCircle]="'right'"
                                                 [style.transform]="'scale(' + detailData.trend.scale.right + ')'"></div>
                                        </div>
                                    </div>

                                    <div class="today-stats-wrapper">
                                        <div class="today-stats">
                                            <div class="muted">
                                                <div class="day m-b-s">
                                                    <div>
                                                        <app-date-picker-button
                                                            [alternativeClass]="'date-picker-today'"
                                                            [currentDate]="detailData.comparisonDate.date"
                                                            (dateChanged)="onComparisonDateChange($event)">
                                                        </app-date-picker-button>
                                                    </div>
                                                </div>
                                                <div *ngIf="!isVisionUser" class="font-medium cost m-b-s">
                                                    {{detailData.comparisonDate.costs | todayValueFormat}}
                                                    {{LABELS.UNIT_COST}}
                                                </div>
                                                <div class="font-medium cost m-b-s" [ngClass]="{'consumption-value': isVisionUser}">
                                                    {{detailData.comparisonDate.consumption | todayValueFormat}}
                                                    {{LABELS.UNIT_CONSUMPTION}}
                                                </div>
                                            </div>
                                            <!--                                <div [class.active]="trend.direction < 0"-->
                                            <!--                                     [class.warn]="trend.direction > 0">-->
                                            <div class="white">
                                                <div class="font-regular day right m-b-s">
                                                    {{LABELS.LABEL_COMPARISON_TODAY}}
                                                </div>
                                                <div *ngIf="!isVisionUser" class="font-medium cost m-b-s">
                                                    {{detailData.today.costs | todayValueFormat}}
                                                    {{LABELS.UNIT_COST}}
                                                </div>
                                                <div class="font-medium cost m-b-s" [ngClass]="{'consumption-value': isVisionUser}">
                                                    {{detailData.today.consumption | todayValueFormat }}
                                                    {{LABELS.UNIT_CONSUMPTION}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="today-stats-summary font-regular" [ngClass]="{'no-border': isVisionUser}">
                                            <ng-container
                                                *ngIf="detailData.trend.percentage > 0 && detailData.trend.percentage < 9999">
                                                <strong>
                                                    {{detailData.trend.percentage}}
                                                    {{LABELS.LABEL_FRAGMENT_PERCENT}}
                                                    {{ detailData.trend.direction > 0 ? LABELS.MORE : LABELS.LESS}}
                                                    &nbsp;
                                                </strong>
                                                <ng-container *ngIf="!dateChanged">
                                            <span>
                                                {{LABELS.LABEL_FRAGMENT_LAST}}
                                                {{detailData.comparisonDate.date | nameOfDay}}
                                            </span>
                                                </ng-container>
                                                <ng-container *ngIf="dateChanged">
                                            <span>
                                                {{LABELS.LABEL_FRAGMENT_COMPARED_TO}}
                                                {{detailData.comparisonDate.date | date:'dd.MM.yyyy'}}
                                            </span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="detailData.trend.percentage === 0">
                                                <strong>{{LABELS.IDENTICAL}}</strong>
                                                &nbsp;
                                                <ng-container *ngIf="!dateChanged">
                                            <span>
                                                {{LABELS.LABEL_FRAGMENT_TO_LAST}}
                                                {{detailData.comparisonDate.date | nameOfDay}}
                                            </span>
                                                </ng-container>
                                                <ng-container *ngIf="dateChanged">
                                            <span>
                                                {{LABELS.LABEL_FRAGMENT_TO}}
                                                {{detailData.comparisonDate.date | date:'dd.MM.yyyy'}}
                                            </span>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="ViewState.ERROR">
                                    <div class="center-contents">
                                        {{LABELS.LABEL_ERROR_STATE}}
                                    </div>
                                </ng-container>
                            </ng-container>


                        </ng-container>
                    </div>
                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">{{TEXTS.INFO.TITLE}}</h2>
                    <p *ngFor="let p of TEXTS.INFO.PARAGRAPHS">
                        {{p}}
                    </p>
                </div>
            </section>

            <section class="detail-content">
                <div class="header-row">
                    <h3>{{LABELS.OVERVIEW_HOURS}}</h3>
                    <app-toggle
                        icon="timeframe"
                        (stateChanged)="toggleEntireListDisplay($event)">
                    </app-toggle>
                </div>

                <ng-container *ngIf="todayDetailData$ | async as detailData">
                    <ng-container [ngSwitch]="detailData.viewState">
                        <ng-container *ngSwitchDefault>
                            <ul class="hours-list">
                                <li *ngFor="let hour of detailData.today.hours; index as i">
                                    <div>
                                        <div class="list-time">
                                            {{ hour.hour }} {{ LABELS.HOUR_START_SUFFIX }}
                                            {{LABELS.TIME_DIVIDER}}
                                            {{ hour.hour }} {{ LABELS.HOUR_END_SUFFIX }}
                                            {{ LABELS.TIME_SUFFIX }}
                                        </div>
                                        <div class="list-date">
                                            {{ LABELS.LIST_TODAY }}
                                        </div>
                                        <div class="list-consumption">
                                            {{ hour.consumption | todayValueFormat }}
                                            {{ LABELS.UNIT_CONSUMPTION }}
                                        </div>
                                        <div  *ngIf="!isVisionUser"  class="list-cost">
                                            {{ hour.costs | todayValueFormat }}
                                            {{ LABELS.UNIT_COST}}
                                        </div>
                                    </div>
                                    <div class="comparison-hours">
                                        <div class="list-time"></div>
                                        <div class="list-date">
                                            {{ detailData.comparisonDate.date | date:'dd.MM.yyyy' }}
                                        </div>
                                        <div class="list-consumption">
                                            {{ detailData.comparisonDate.hours[i].consumption | todayValueFormat }}
                                            {{ LABELS.UNIT_CONSUMPTION }}
                                        </div>
                                        <div *ngIf="!isVisionUser" class="list-cost">
                                            {{ detailData.comparisonDate.hours[i].costs | todayValueFormat }}
                                            {{ LABELS.UNIT_COST }}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="ViewState.ERROR">
                            <div class="center-content">
                                {{LABELS.LABEL_ERROR_STATE}}
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </section>

        </div>
    </div>
</div>
