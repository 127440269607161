import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';
import {MockDataService} from '../../services/mock-data.service';
import {UserService} from '../../services/user.service';
import {ApplicationService} from '../../services/application.service';
import {InitializationService} from '../../services/initialization.service';
import {ProfileService} from '../../services/profile.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {RegistrationService} from '../../services/registration.service';
import {MeterService} from '../../services/meter.service';
import * as moment from 'moment';
import {Popover} from '../../popovers/popover/popover.service';
import {ChangeEmailComponent} from '../../popovers/change-email/change-email.component';
import {ResetPasswordComponent} from '../../popovers/reset-password/reset-password.component';
import {ProfileLabels} from '../../shared/texts/profile.labels';
import {Tariff} from '../../classes/user';
import {
    TariffDataEntryComponent
} from '../../popovers/tariff-data-entry/tariff-data-entry.component';
import {TariffHistoryComponent} from '../../popovers/tariff-history/tariff-history.component';
import {
    getProviderIconClass,
    getProviderMenuName,
    getProviderSettingsLink,
    ProviderMappings
} from '../../lib/ProviderUtil';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {MfaService} from '../../services/mfa.service';
import {
    DisableMFAConfirmationPopover,
    MFACodeEntryPopover,
    MFACodePopover
} from '../../popovers/static.popover.config';
import {catchError, mergeMap} from 'rxjs/operators';
import {iif, of, throwError} from 'rxjs';
import {AccountsTexts} from '../../shared/texts/accounts.texts';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import {AccountRewriteService} from '../../services/account-rewrite.service';


@Component({
    selector: 'iona-app',
    styleUrls: ['profile.component.scss'],
    templateUrl: './profile.component.html',
    viewProviders: []
})

export class ProfileComponent extends BaseComponent implements OnInit, OnDestroy {

    readonly TEXTS = ProfileLabels;
    readonly TEXTS_A = AccountsTexts;

    moment = moment;

    // provider
    provider = '';
    username = null;
    providerclass = '';
    providerDisplayName = '';
    isVisionUser = true;

    // tariff
    contract: any = {
        name: '',
        tarif: null,
        basicprice: null,
        workingprice: null
    };

    isERNAUser = false;
    tariffs: Tariff[] = [];
    activeTariff: Tariff = null;
    tariffsAvailable = false;
    createTariffDisabled = false;

    mfaEnabled = false;

    buttonTitle = 'Mein iONA';
    providerSettingsLink = '';
    accountRewriteEnabled = false;


    constructor(public application: ApplicationService,
                public accountRewrite: AccountRewriteService,
                private title: Title,
                private toastrService: ToastrService,
                private mockDataService: MockDataService,
                private userService: UserService,
                private initialization: InitializationService,
                private profile: ProfileService,
                private versionService: VersionService,
                private meter: MeterService,
                private registration: RegistrationService,
                private popover: Popover,
                private optInService: LocalOptInService,
                private mfaService: MfaService,
                private gtm: GTMWrapperService,
    ) {
        super();
    }


    ngOnInit() {
        this.title.setTitle('Meine Daten | iONA');
        this.optInService.checkStatus();
        this.checkIsVisionUser();

        this.username = this.userService.getActiveUserName();
        this.provider = this.userService.getActiveUserProvider();

        this.buttonTitle = getProviderMenuName(this.userService.getActiveUserProvider());
        this.providerSettingsLink = getProviderSettingsLink(this.userService.getActiveUserProvider());

        this.providerclass = getProviderIconClass(this.userService.getActiveUserProvider());
        try {
            this.providerDisplayName = ProviderMappings.find(
                el => el.name === this.userService.getActiveUserProvider()
            ).displayName;
        } catch (e) {
            this.providerDisplayName = this.userService.getActiveUserProvider();
        }
        this.initialize();

        this.requestMFAStatus();
        this.accountRewriteEnabled = this.accountRewrite.accountRewriteEnabled();
    }


    checkIsVisionUser(): void {
        this.initialization.getWithCache().subscribe((data) => {
            if('product_name' in data){
                this.isVisionUser = data.product_name.toLowerCase().includes('vision');
            }
        })
    }


    /**
     * Initialize the view
     */
    initialize(): void {
        const initSub = this.initialization.get(
            false,
            this.accountRewrite.accountRewriteEnabled()
        ).subscribe((res) => {
            if (res && 'profile' in res) {
                this.processContractData(res);
            }
        });
        this.addSub(initSub);

        const profileSub = this.profile.getContract(
            this.accountRewrite.accountRewriteEnabled()
        ).subscribe(
            (res: any) => {
                if ('profile' in res) {
                    this.processProfileData(res);
                }
            }
        );
        this.addSub(profileSub);

        this.isERNAUser = this.userService.isEDGUser();
        if (this.isERNAUser) {
            this.initializeTariffs();
        }
        this.addSub(this.profile.getContract().subscribe(
            (res: any) => {
                if (res && 'profile' in res) {
                    this.processProfileData(res);
                }
            }
        ));
    }


    /**
     * Opens an email change request form overlay
     */
    openChangeEmailPopover(): void {
        const s = this.popover.open({
            content: ChangeEmailComponent,
            data: {},
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res: any) => {
            if (!res.data) {
                s.unsubscribe();
                return;
            }
            if (res.data.success) {
                this.requestEmailChange(res.data.value);
            }
            s.unsubscribe();
        });
    }


    /**
     * Opens an password change request form overlay
     */
    openResetPasswordPopover(): void {
        const s = this.popover.open({
            content: ResetPasswordComponent,
            data: {},
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res: any) => {
            if (!res.data.success) {
                s.unsubscribe();
                return;
            }
            const d = res.data.values as
                { oldPassword: string, firstNewPassword: string, secondNewPassword: string };
            this.requestPasswordChange(d.oldPassword, d.firstNewPassword, d.secondNewPassword);
            s.unsubscribe();
        });
    }


    /**
     * Opens an overlay to create a new tariff
     * ERNA-USERS ONLY!
     */
    openNewTariffCreationPopover(): void {
        const s = this.popover.open({
            content: TariffDataEntryComponent,
            data: {
                tariffs: this.tariffs
            },
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res) => {
            if (!res.data) {
                console.log('Something went wrong here');
                return;
            }
            const mode = res.data['mode'];
            if (mode === 'create') {
                if (res.data['data']) {
                    const d = res.data['data'] as any;
                    d.dateEnd = null;
                    this.userService.addTarrifInfo(d);
                    this.initializeTariffs();
                }
            }
            s.unsubscribe();
            this.initializeTariffs();
        });
    }


    /**
     * Opens an overlay showing the tariff history
     * ERNA-USERS ONLY!
     */
    openTariffHistoryPopover(): void {
        const s = this.popover.open({
            content: TariffHistoryComponent,
            data: {
                provider: this.provider,
                tariffs: this.tariffs
            },
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res) => {
            this.initializeTariffs();
            s.unsubscribe();
        });
    }


    /**
     * Opens an overlay to edit the currently set tariff
     * ERNA-USERS ONLY!
     */
    editCurrentTariff(): void {
        const currentIdx = this.getActiveTariffIdx();

        const s = this.popover.open({
            content: TariffDataEntryComponent,
            data: {
                tariffs: this.tariffs,
                edit_mode: true,
                edit_data: this.tariffs[currentIdx],
                edit_idx: currentIdx
            },
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res) => {
            if (!res.data) {
                console.log('Something went wrong here');
                return;
            }
            const mode = res.data['mode'];
            if (mode === 'update') {
                if (res.data['data']) {
                    const d = res.data['data'] as any;
                    d.dateEnd = null;
                }
                this.tariffs[currentIdx].name = res.data['data'].name;
                this.tariffs[currentIdx].basePrice = res.data['data'].basePrice;
                this.tariffs[currentIdx].workPrice = res.data['data'].workPrice;
                this.userService.updateTariffs(this.tariffs.reverse());
            }
            if (mode === 'delete') {
                this.userService.removeTariff(res.data['data']);
            }
            s.unsubscribe();
            this.initializeTariffs();
        });
    }


    /**
     * Determine the display string for a price
     * @param value
     */
    determinePrice(value: number): string {
        return value.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
    }


    /**
     * Returns the user-set name for the currently active tariff
     */
    getCurrentTariffName(): string {
        if (this.tariffsAvailable) {
            if (this.activeTariff) {
                return this.activeTariff.name;
            }
        }
        return this.contract.tarif;
    }


    /**
     * Returns the base-price of the currently active tariff
     */
    getCurrentTariffBasePrice(): string {
        if (this.tariffsAvailable) {
            if (this.activeTariff) {
                return this.determinePrice(this.activeTariff.basePrice);
            }
        }
        return this.contract.basicprice;
    }


    /**
     * Returns the work-price of the currently active tariff
     */
    getCurrentTariffWorkPrice(): string {
        let price = this.contract.workingprice;
        let suffix = '€/kWh';
        if (this.tariffsAvailable) {
            if (this.activeTariff) {
                const temp = this.activeTariff.workPrice * 100;
                if (temp < 100) {
                    price = temp;
                } else {
                    price = this.activeTariff.workPrice;
                }
            }
        }
        if (!price) {
            return `0,00 ${suffix}`;
        }
        if (price < 100) {
            price = price.toFixed(2).replace('.', ',');
            suffix = 'ct/kWh';
        }
        return `${price} ${suffix}`;
    }


    /**
     * Trigger requests to either enable or disable MFA for the logged in user
     */
    enableDisableMFA(): void {
        if (!this.mfaEnabled) {
            this.triggerMfaEnablePipeline();
            return;
        }
        this.triggerMfaDisablePipeline();

    }


    /**
     * Wrapper function to open a link
     */
    openLink() {
        window.open(this.providerSettingsLink, '');
    }


    /**
     * Determine the username to be displayed
     */
    determineDisplayUsername(): string {
        if (this.accountRewriteEnabled) {
            return this.accountRewrite.userToRewrite;
        }
        return this.username === 'Demo' || this.username === 'demo'
            ? 'kundenservice@iona-energy.com' : this.username;
    }


    /**
     * Request email change
     * @param email
     */
    private requestEmailChange(email: string) {
        if (this.application.isDemoMode()) {
            this.toastrService.info(
                'Die E-Mail-Adresse kann im Demo-Modus nicht geändert werden!');
            return;
        }
        const sub = this.registration.setEmail(email).subscribe(
            // this._apiService.setEmail(email).subscribe(
            (res) => {
                this.toastrService.success('Ihre E-Mail-Adresse wurde geändert!');
                this.username = email;
                this.trackEmailChangeSuccessful();
            },
            (error: any) => {
                this.trackEmailChangeErrorEvent(error);
                if (error._body) {
                    switch (error.error.code) {
                        case 104: {
                            this.toastrService.error('Ihre E-Mail-Adresse ist fehlerhaft!');
                            break;
                        }
                        default: {
                            this.toastrService.error(
                                'Ihre E-Mail-Adresse konnte nicht geändert werden!');
                        }
                    }
                } else {
                    this.toastrService.error('Ihre E-Mail-Adresse konnte nicht geändert werden!');
                }
                sub.unsubscribe();
            }
        );
    }


    /**
     * Request password change
     * @param oldPass
     * @param newPass
     * @param newPassRepeat
     */
    private requestPasswordChange(oldPass: string, newPass: string, newPassRepeat: string) {
        if (this.application.isDemoMode()) {
            return this.toastrService.info(
                'Das Passwort kann im Demo-Modus nicht geändert werden!'
            );
        }

        if (oldPass.length === 0 || newPassRepeat.length === 0 || newPassRepeat.length === 0) {
            this.toastrService.error('Bitte alle Felder ausfüllen!');
        }

        this.registration.updatePassword(oldPass, newPass, newPassRepeat).subscribe(
            // this._apiService.updatePassword(oldPass, newPass, newPassRepeat).subscribe(
            () => {
                this.toastrService.success('Ihr Passwort wurde geändert!');
                this.trackPasswordChangeSuccessful();
            },
            (error: any) => {
                if (!('error' in error)) {
                    return;
                }
                if (!('error' in error.error)) {
                    return;
                }
                this.trackPasswordChangeErrorEvent(error);
                if (error.error.error.code === 264) {
                    const msg = error.error.error.message;
                    if (msg.includes('MAX length')) {
                        this.toastrService.error(
                            'Das gewählte Passwort ist zu lang. Bitte verwenden Sie maximal 16 Zeichen.',
                            'Passwort ungültig',
                            {timeOut: 6000}
                        );
                    } else if (msg.includes('Special characters')) {
                        this.toastrService.error('Das gewählte Passwort enthält unzulässige Sonderzeichen. ' +
                            'Bitte verwenden Sie nur Buchstaben und Zahlen, sowie die folgenden Sonderzeichen: ' +
                            '! \" ( ) = [ ] { } ? \\ + * ~ # , ; . - _ Umlaute und ß sind nicht möglich.',
                            'Passwort ungültig',
                            {timeOut: 6000}
                        );
                    }
                }
            }
        );
    }


    /**
     * Triggers the cycle necessary to ENABLE Multi-Factor-Authentication
     */
    private triggerMfaEnablePipeline(): void {
        const mfaCodeConfig = MFACodePopover;
        this.mfaService.enableMFA().pipe(
            mergeMap(secretCode => {
                mfaCodeConfig.data.setupCode = secretCode;
                return of(mfaCodeConfig);
            }),
            mergeMap(config =>
                this.popover.open(config).afterClosed$.pipe(
                    mergeMap(event => of(event.data))
                )),
            // tap(result => console.log(result)),
            mergeMap((result: any) =>
                result ? this.popover.open(MFACodeEntryPopover).afterClosed$ : throwError(null)
            )
        ).subscribe((result => {
            if (result) {
                this.requestMFAStatus();
            }
        }));
    }


    /**
     * Triggers the cycle necessary to DISABLE Multi-Factor-Authentication
     */
    private triggerMfaDisablePipeline(): void {
        this.popover.open(DisableMFAConfirmationPopover).afterClosed$.pipe(
            mergeMap((closedEvent: any) => of(closedEvent.data)),
            // tap(result => console.log(result)),
            mergeMap((result: boolean) =>
                iif(() => result === true,
                    this.mfaService.disableMFA().pipe(
                        mergeMap(response =>
                            of({success: true, data: response, reason: null})),
                        catchError(error =>
                            of({success: false, data: null, reason: 'api-error'}))
                    ),
                    of({success: false, data: null, reason: 'user-abort'})
                ))
        ).subscribe((result: { success: boolean, data: any, reason: string }) => {
            if (!result.success) {
                if (result.reason === 'api-error') {
                    this.toastrService.error('Hoppla, da ist etwas schief gelaufen.', 'Fehler');
                }
            } else {
                this.requestMFAStatus();
            }
        });
    }


    /**
     * Process contract data
     * @param data
     */
    private processContractData(data): void {
        if (this.application.isDemoMode()) {
            this.contract.name = 'Maxi Mustermann';
        } else {
            this.contract.name = data.profile.customer_name;
        }
        this.contract.tarif = data.profile.product_name;
    }


    /**
     * Process Profile information
     * @param data
     */
    private processProfileData(data): void {
        let basicprice = 0;
        if (typeof data.profile.e_fixed_tariff !== 'undefined') {
            basicprice = parseFloat(data.profile.e_fixed_tariff);
        } else {
            basicprice = parseFloat(data.profile.budget_bill);
        }

        const workingprice = parseFloat(data.profile.e_tariff);
        this.contract.basicprice = basicprice.toLocaleString('de-DE',
            {style: 'currency', currency: 'EUR'}
        );
        this.contract.workingprice = workingprice * 100;

    }


    /**
     * Initialize tarrifs stored by the user
     * @private
     */
    private initializeTariffs(): void {
        const stored = this.userService.getTariffInfo();
        this.tariffs = stored ? stored : [];
        this.tariffsAvailable = false;
        this.createTariffDisabled = false;
        this.activeTariff = null;
        if (this.tariffs.length > 0) {
            this.tariffsAvailable = true;
            const latest = this.tariffs[this.tariffs.length - 1];
            const d = new Date(latest.dateStart);
            const now = moment().hours(2).minutes(0).seconds(0).milliseconds(0);
            if (d >= now.toDate()) {
                this.createTariffDisabled = true;
            }
            this.activeTariff = this.tariffs[this.getActiveTariffIdx()];
        }
    }


    /**
     * Returns the index of the currenlty active tariff
     * @private
     */
    private getActiveTariffIdx(): number {
        const comparison_date = moment().hours(2).minutes(0).seconds(0).milliseconds(0).toDate();
        return this.tariffs.findIndex((el) => {
            const start = new Date(el.dateStart);
            const end = el.dateEnd ? new Date(el.dateEnd) : new Date();
            return comparison_date >= start && comparison_date <= end;
        });
    }


    /**
     * Initially get the MFA status of the currently logged in user
     */
    private requestMFAStatus(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        this.mfaService.getMFAStatus()
            // .pipe(tap(res => console.log('MFA status res', res)))
            .subscribe((mfaStatus) => this.mfaEnabled = mfaStatus);
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackEmailChangeErrorEvent(error: any): void {
        this.gtm.trackEvent({
            event: 'error',
            eventCategory: 'monitoring',
            eventAction: 'error',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit email address',
            errorMessage: error.error.message,
            errorReason: error.error.code,
        });
    }


    private trackEmailChangeSuccessful(): void {
        this.gtm.trackEvent({
            event: 'submit',
            eventCategory: 'conversion',
            eventAction: 'submit',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit email address'
        });
    }


    private trackPasswordChangeErrorEvent(error: any): void {
        this.gtm.trackEvent({
            event: 'error',
            eventCategory: 'monitoring',
            eventAction: 'error',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit password',
            errorMessage: error.error.error.message,
            errorReason: error.error.error.code,
        });
    }


    private trackPasswordChangeSuccessful(): void {
        this.gtm.trackEvent({
            event: 'submit',
            eventCategory: 'conversion',
            eventAction: 'submit',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit password'
        });
    }


}
