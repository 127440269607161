<div id="container">
    <app-menu activeElement="household"></app-menu>
    <div id="inner">
        <main class="page">
            <div class="page-card page-card-override">
                <div>
                    <h1>{{TEXTS.TITLE}}</h1>
                    <strong>{{TEXTS.GREETING}} {{fullname}},</strong>
                    <p>{{TEXTS.SUBTITLE}}</p>
                </div>
                <section>
                    <p class="teaser">{{propertyType.title }}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="propertyType.selectedValue; else typeEmpty">
                                <i class="step-complete"></i>
                            </ng-container>
                            <ng-template #typeEmpty>
                                <i class="step-in-progress"></i>
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of propertyType.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="propertyType.selectedValue">
                                    <div class="item-image"
                                         [style.mask-image]="determineImagePath(propertyType, item)"
                                         [style.-webkit-mask-image]="determineImagePath(propertyType, item)"
                                         [style.background-color]="isActive(propertyType, item) ? '#f59b01' : '#333'">
                                    </div>
                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{propertySize.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="propertySize.selectedValue; else sizeEmpty">
                                <i class="step-complete"></i>
                            </ng-container>
                            <ng-template #sizeEmpty>
                                <i class="step-in-progress"></i>
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of propertySize.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="propertySize.selectedValue">

                                    <div class="item-image"
                                         [style.mask-image]="determineImagePath(propertySize, item)"
                                         [style.-webkit-mask-image]="determineImagePath(propertySize, item)"
                                         [style.background-color]="isActive(propertySize, item) ? '#f59b01' : '#333'">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{propertyAge.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="propertyAge.selectedValue; else ageEmpty">
                                <i class="step-complete"></i>
                            </ng-container>
                            <ng-template #ageEmpty>
                                <i class="step-in-progress"></i>
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of propertyAge.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="propertyAge.selectedValue">

                                    <div class="item-image"
                                         [style.mask-image]="determineImagePath(propertyAge, item)"
                                         [style.-webkit-mask-image]="determineImagePath(propertyAge, item)"
                                         [style.background-color]="isActive(propertyAge, item) ? '#f59b01' : '#333'">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <p class="teaser">{{occupants.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="occupants.selectedValue; else occEmpty">
                                <i class="step-complete"></i>
                            </ng-container>
                            <ng-template #occEmpty>
                                <i class="step-in-progress"></i>
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of occupants.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="occupants.selectedValue">

                                    <div class="item-image"
                                         [style.mask-image]="determineImagePath(occupants, item)"
                                         [style.-webkit-mask-image]="determineImagePath(occupants, item)"
                                         [style.background-color]="isActive(occupants, item) ? '#f59b01' : '#333'">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{spaceHeating.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="spaceHeating.selectedValue; else spaceEmpty">
                                <i class="step-complete"></i>
                            </ng-container>
                            <ng-template #spaceEmpty>
                                <i class="step-in-progress"></i>
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of spaceHeating.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="spaceHeating.selectedValue">

                                    <div class="item-image"
                                         [style.mask-image]="determineImagePath(spaceHeating, item)"
                                         [style.-webkit-mask-image]="determineImagePath(spaceHeating, item)"
                                         [style.background-color]="isActive(spaceHeating, item) ? '#f59b01' : '#333'">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{waterHeating.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="waterHeating.selectedValue; else waterEmpty">
                                <i class="step-complete"></i>
                            </ng-container>
                            <ng-template #waterEmpty>
                                <i class="step-in-progress"></i>
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of waterHeating.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="waterHeating.selectedValue">

                                    <div class="item-image"
                                         [style.mask-image]="determineImagePath(waterHeating, item)"
                                         [style.-webkit-mask-image]="determineImagePath(waterHeating, item)"
                                         [style.background-color]="isActive(waterHeating, item) ? '#f59b01' : '#333'">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{photovoltaic.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="photovoltaic.selectedValue; else photovoltaicEmpty">
                                <i class="step-complete"></i>
                            </ng-container>
                            <ng-template #photovoltaicEmpty>
                                <i class="step-in-progress"></i>
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of photovoltaic.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="photovoltaic.selectedValue">

                                    <div class="item-image"
                                         [style.mask-image]="determineImagePath(photovoltaic, item)"
                                         [style.-webkit-mask-image]="determineImagePath(photovoltaic, item)"
                                         [style.background-color]="isActive(photovoltaic, item) ? '#f59b01' : '#333'">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{appliances.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="determineAppliancesSelected(); else waterEmpty">
                                <i class="step-complete"></i>
                            </ng-container>
                            <ng-template #waterEmpty>
                                <i class="step-in-progress"></i>
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of appliances.items">
                                <label class="category-item">
                                    <input type="checkbox" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="item.selected">

                                    <div class="item-image"
                                         [style.mask-image]="determineImagePath(appliances, item)"
                                         [style.-webkit-mask-image]="determineImagePath(appliances, item)"
                                         [style.background-color]="item.selected ? '#f59b01' : '#333'">
                                    </div>

                                    <div class="item-label"
                                         [style.color]="item.selected ? '#f59b01' : '#333'"
                                         [ngClass]="{'font-bold': item.selected}">
                                        {{item.title}}
                                    </div>
                                </label>

                                <div class="item-input">
                                    <app-count-selector [hidden]="!item.selected"
                                                        [(ngModel)]="item.count"
                                                        (ngModelChange)="onCountChanged(item, $event)">
                                    </app-count-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <button class="iona-button orange" (click)="saveSettings()">{{TEXTS.BUTTON_SAVE}}</button>
                </section>

            </div>
        </main>
    </div>
</div>
