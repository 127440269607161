export enum FinanceDetailLabels {
    TITLE = 'Abschlags-Check',
    LABEL_FRAGMENT_CIRCA = 'etwa',
    LABEL_FRAGMENT_ADDITIONAL_PAYMENT = 'Nachzahlung',
    LABEL_FRAGMENT_CREDIT = 'Gutschrift',
    LABEL_CONSUMPTION_MATCHES = 'Verbrauch passt genau',
    UNIT_FINANCE = '€',
    LABEL_EMPTY_STATE = 'Daten werden noch gesammelt',
    LABEL_TIMEFRAME = 'Abrechnungszeitraum',
    BTN_LABEL_ADJUST = 'Abschlag ändern',
}


export const FinanceDetailTexts = {
    TILE_INFO: {
        TITLE: 'Abschlags-Check: Was ist das?',
        PARAGRAPH: 'Nie mehr nachzahlen. iONA wirft für Sie einen Blick in die Zukunft und berechnet, ob Ihr monatlicher Abschlag ausreicht. Falls nicht, können Sie ihn online anpassen. Die Vorhersage von iONA wird jeden Monat besser. Für eine genaue Berechnung werden ca. sechs Monatsverbräuche benötigt.'
    },
    TREND: {
        CASE_CREDIT: 'Auf Basis Ihres aktuellen Verbrauchs ergibt sich zur Jahresrechnung voraussichtlich eine Gutschrift von bis zu ',
        CASE_MATCHES: 'Auf Basis der ermittelten Daten passt der Abschlag. ',
        CASE_ADDITIONAL_PAYMENT: 'Auf Basis Ihres aktuellen Verbrauchs ergibt sich zur Jahresrechnung voraussichtlich eine Nachzahlung von bis zu',
        CASE_UNIT: 'Euro'
    },
    ADJUST_ONLINE: 'Sie können ihren Abschlag jederzeit online anpassen.',
    INFO: 'Die Vorhersage von iONA wird jeden Monat besser. Für eine genaue Berechnung werden ca. sechs Monatsverbräuche benötigt.',
    BANNER_TEXT: 'Die Strompreisbremse fließt nicht in die Berechnung des Abschlagscheckes ein, daher können eventuell Abweichungen auftreten.'
};
