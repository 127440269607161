import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {static_links} from '../../../shared/constants/web-resources.constants';
import {UserService} from '../../../services/user.service';
import { financeProfileUpdateBanner } from '../../../shared/constants/detail-banners/finance-details-banners.constants';
import * as moment from 'moment';
import {ApplicationService} from '../../../services/application.service';
import {MONTHS} from '../../../lib/DateUtil';
import {AnimationOptions} from 'ngx-lottie';
import {
    FinanceDetailLabels,
    FinanceDetailTexts
} from '../../../shared/texts/detail/finance-detail.labels';
import {Router} from '@angular/router';
import {FinanceDataService} from '../../../services/finance-data.service';
import {ignoreElements, Observable, of, share} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageAttributes } from '../../../shared/constants/storage-attributes.constants';

@Component({
    selector: 'app-finance-details',
    templateUrl: './finance-details.component.html',
    styleUrls: ['./finance-details.component.scss'],
    viewProviders: [ApiService]
})

export class FinanceDetailsComponent extends BasePopover implements OnInit {
    readonly LABELS = FinanceDetailLabels;
    readonly TEXTS = FinanceDetailTexts;

    protected bannerData = financeProfileUpdateBanner;

    bannerVisible = false;

    financeData$ = this.financeDataService.getFinancialTrend().pipe(
        share()
    );
    financeDataError$: Observable<boolean> = this.financeData$.pipe(
        ignoreElements(),
        catchError(error => of(true))
    );

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/finance-detail-2.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Smart Meter'
    };

    infoVisible = false;

    constructor(protected popoverRef: PopoverRef,
                public userService: UserService,
                private analytics: TrackAnalyticsService,
                private application: ApplicationService,
                private router: Router,
                private financeDataService: FinanceDataService) {
        super(popoverRef);
    }

    ngOnInit() {
        this.getShouldShowBanner();
    }

    /**
     * Format the end date for beginning of the current year
     * @param date
     */
    formatStartDate(date: Date): string {
        if (this.application.isDemoMode()) {
            const now = new Date();
            return `01.01.${now.getFullYear()}`;
        }
        return this.formatDate(date);
    }

    getShouldShowBanner(): void {
        if (this.application.isDemoMode()){
            return;
        }
        this.bannerVisible = localStorage.getItem(StorageAttributes.SHOW_ABSCHLAGS_CHECK_BANNER) === '0' ? false : true;
    }

    hideInfoBanner() {
        localStorage.setItem(
            StorageAttributes.SHOW_ABSCHLAGS_CHECK_BANNER, '0'
        );

        this.bannerVisible = false;
    }

    /**
     * Format the end date for end of the current year
     * @param date
     */
    formatEndDate(date: Date): string {
        if (this.application.isDemoMode()) {
            const now = new Date();
            return `31.12.${now.getFullYear()}`;
        }
        return this.formatDate(date);
    }


    /**
     * Opens a static link
     */
    openLink(): void {
        const link = static_links.finance[this.userService.getActiveUserProvider()];
        window.open(link);
    }

    /**
     * Format date wrapper
     * @param date
     * @private
     */
    private formatDate(date: Date): string {
        const parsedDate = moment(date);
        return parsedDate
            .format(`DD. ** YYYY`)
            .replace('**', MONTHS[parsedDate.month()]);
    }
}
