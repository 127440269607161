import {Component, OnInit} from '@angular/core';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/data-provider/household-comparison-data-provider.service';
import {
    HouseholdComparisonDetailTexts
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.texts';
import {
    HouseholdComparisonDetailLabels
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.labels';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {
    HouseholdComparisonData,
    HouseholdComparisonRankingData,
    RankingPosition
} from '../../../shared/interfaces/household-comparison.interfaces';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';


@Component({
    selector: 'app-household-comparison-rank',
    templateUrl: './household-comparison-rank.component.html',
    styleUrls: ['./household-comparison-rank.component.scss']
})
export class HouseholdComparisonRankComponent implements OnInit {
    readonly LABELS = HouseholdComparisonDetailLabels;
    readonly TEXTS = HouseholdComparisonDetailTexts;

    protected readonly RankingPosition = RankingPosition;
    protected readonly ViewState = ViewState;


    combinedClusterRankData$: Observable<{
        householdRankData: HouseholdComparisonRankingData,
        combinedClusterRankData: HouseholdComparisonData
    }> = this.dataProvider.rankingData$.pipe(
        mergeMap(householdRankData => {
            return this.dataProvider.combinedClusterRankingData$.pipe(
                map(combinedClusterRankData => ({householdRankData, combinedClusterRankData})),
            );
        }),
    );


    constructor(
        private dataProvider: HouseholdComparisonDataProviderService,
    ) {
    }


    ngOnInit(): void {
        this.dataProvider.getRankingData();
    }

}
