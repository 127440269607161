import {Component, OnInit} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {
    ConnectionIndicatorConfig
} from '../../../components/connection-indicator/connection-indicator.component';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {MeterConnectionConfig} from '../../../shared/constants/connection.constants';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {MeterTileTexts} from '../../../shared/texts/tiles/meter-tile.texts';
import {MeterDataProviderService} from '../../../services/data-provider/meter-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {InternalMeterConnectionStatus} from '../../../shared/enums/meter-status.enum';


@Component({
    selector: 'app-meter-tile',
    templateUrl: './meter-tile.component.html',
    styleUrls: ['./meter-tile.component.scss'],
    providers: [Globals]
})

export class MeterTileComponent extends BaseComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.METER;

    protected readonly ViewState = ViewState;

    readonly TEXTS = MeterTileTexts;

    connectionConfig: ConnectionIndicatorConfig = MeterConnectionConfig;

    meterData$ = this.meterDataProvider.combinedMeterData$.asObservable();


    constructor(
        private globals: Globals,
        private analytics: TrackAnalyticsService,
        private tiles: TileService,
        private meterDataProvider: MeterDataProviderService) {
        super();
    }


    ngOnInit() {
        this.meterDataProvider.initializeCombinedMeterDataUpdate();
    }


    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    /**
     * Determine if the meter is connected.
     * @param status
     */
    isMeterConnected(status: InternalMeterConnectionStatus): boolean {
        return status === InternalMeterConnectionStatus.CONNECTED;
    }


    /**
     * Determine meter status message
     * @param status
     */
    determineMeterStatusMessage(status: InternalMeterConnectionStatus): string {
        switch (status) {
            case InternalMeterConnectionStatus.CONNECTED:
                return this.TEXTS.LABEL_CONNECTED;
            default:
                return this.TEXTS.LABEL_DISCONNECTED;
        }
    }


    /**
     * Analytics stuff
     * =============================================================================================
     * @private
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Meter'
            }
        });
    }


    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Meter-Details'
            }
        });
    }


}
