import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import * as moment from 'moment';
import {TodayDetailLabels} from '../../../shared/texts/detail/today-detail.labels';
import {TodayDetailTexts} from '../../../shared/texts/detail/today-detail.texts';
import {
    TodayDataProviderService
} from '../../../services/data-provider/today-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {share} from 'rxjs';
import { InitializationService} from '../../../services/initialization.service';


@Component({
    selector: 'app-today-details',
    templateUrl: './today-details.component.html',
    styleUrls: ['./today-details.component.scss'],
    viewProviders: []
})

export class TodayDetailsComponent extends BasePopover implements OnInit, OnDestroy {
    protected readonly LABELS = TodayDetailLabels;
    protected readonly TEXTS = TodayDetailTexts;
    protected readonly ViewState = ViewState;

    currentComparisonDate = moment().subtract(7, 'days').toDate();

    infoVisible = false;

    dateChanged = false;
    showEntireList = false;

    isVisionUser: boolean = true;

    todayDetailData$ = this.todayDataProviderService.todayDetailData$.pipe(
        share()
    );


    constructor(
        protected popoverRef: PopoverRef,
        private todayDataProviderService: TodayDataProviderService,
        private initialization: InitializationService
    ) {
        super(popoverRef);
    }


    ngOnInit() {
        this.todayDataProviderService.getTodayComparisonForSetComparisonDate();
        this.checkIsVisionUser();
    }


    checkIsVisionUser(): void {
        this.initialization.getWithCache().subscribe((data) => {
            if('product_name' in data){
                this.isVisionUser = data.product_name.toLowerCase().includes('vision');
            }
        })
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        this.todayDataProviderService.killDetailSub();
    }


    /**
     * Callback on comparison date change
     * @param selectedValue
     */
    onComparisonDateChange(selectedValue: Date) {
        this.currentComparisonDate = selectedValue;
        this.dateChanged = true;
        this.todayDataProviderService.getTodayComparisonForSetComparisonDate(
            selectedValue, this.showEntireList
        );
    }


    /**
     * Toggle whether the entire list of hours should be shown
     */
    toggleEntireListDisplay(state): void {
        this.showEntireList = state;
        this.todayDataProviderService.getTodayComparisonForSetComparisonDate(
            this.currentComparisonDate, state
        );
    }


}

