export const HouseholdComparisonDetailTexts = {
    TILE_INFO: {
        TITLE: 'Haushaltsvergleich: Was ist das?',
        PARAGRAPHS: [
            'Der Haushaltsvergleich gibt Ihnen ein Gefühl, wie Ihr Stromverbrauch zu anderen Haushalten liegt. Sie haben die Möglichkeit die Vergleichsgruppe zu definieren, um sich mit ähnlichen Haushalten zu vergleichen. Neben dem Gesamtverbrauch können auch Gerätekategorien verglichen werden. Sollten Sie eine PV-Anlage betreiben, so berechnet sich der Gesamtverbrauch Ihrer Geräte folgendermaßen: Verbrauch = Netzbezug + kalkulierte PV-Produktion - Einspeisung. Batteriespeicher verfälschen leider die Geräteerkennung und können aus technischen Gründen nicht berücksichtigt werden.',
            'Auf Basis der gewählten Vergleichsgruppe erhalten Sie außerdem Ihre Platzierung in einer Rangliste.',
        ],
    },
    FILTER: {
        TITLE: 'Wählen Sie die Kriterien für Ihre Vergleichsgruppen aus.',
    },
    FILTER_DISPLAY: {
        INFO: 'Ihre eigenen Angaben können unter „Mein Haushalt“ geändert werden.',
        INFO_DEMO_MODE:
            'Diese Funktion steht im Demomodus nicht zur Verfügung.',
    },
    FILTER_INFO:
        'Um alle Filter nutzen zu können, vervollständigen Sie bitte Ihre Angaben im Bereich “Mein Haushalt”.',
    RANKING: {
        MESSAGES: {
            TOP: 'Fantastisch, Sie sind an der Spitze unterwegs – weiter so!',
            UPPER_THIRD:
                'Großartig, Sie gehören zum Spitzenfeld im Ranking - weiter so!',
            BELOW_AVERAGE:
                'Großartig, Ihr Stromverbrauch liegt unter dem Durchschnitt - weiter so!',
            MIDDLE: 'Juhu, Du bist Durchschnitt – weiter so!',
            REST: 'Indem Sie Ihren Energieverbrauch reduzieren, können Sie Ihren Rang weiter verbessern.',
            MEAN_CONSUMPTION: 'Der Durchschnittsverbrauch liegt bei ',
        },
    },
    OPTED_OUT:
        'Wenn Sie die Funktion des Haushaltsvergleichs nutzen möchten, müssen Sie der Anonymisierung Ihres Verbrauchs- und Haushaltsprofils zustimmen.',
};
