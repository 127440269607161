<div class="tile-container">
    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Live; previous_screen: Übersicht"
         title="Details anzeigen" (click)="onTileClicked()">

        <div class="tile-inner">
            <div class="tile-heading">
                <h3 class="tile-heading">{{TEXTS.TITLE}}</h3>
                <div *ngIf="isNotRealtime" class="energy-saver-warning">
                    <i class="energy-saver"></i>
                    {{TEXTS.LABEL_ENERGY_SAVER}}
                </div>
            </div>

            <div>
                <ng-container *ngIf="userHasHappyHour">
                    <div>
                        <app-remaining-time-indicator
                            #happyHourIndicator
                            [size]="36"
                            [overallTime]="60*60"
                            [refreshrate]="10"
                            [indicatorWidth]="6">
                        </app-remaining-time-indicator>
                    </div>
                </ng-container>
            </div>

            <div class="tile-content">
                <div class="live-tile-current-value"
                     [class.alert]="status.trend > 1  && !status.noZone"
                     [class.muted]="isNotRealtime"
                     [class.feedin]="currentConsumption < 0">
                    <i [class.feedin]="currentConsumption < 0"
                       [class.consumption]="currentConsumption >= 0">
                    </i>
                    {{currentConsumptionFormatted()}} {{valueUnit}}
                </div>
                <div class="grow-space">
                    <div class="tile-chart-abs">
                        <app-live-chart #liveChart></app-live-chart>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
