import {Component, Input, OnInit} from '@angular/core';
import {
    HouseholdComparisonRankingDataEntry
} from '../../../shared/interfaces/household-comparison.interfaces';
import {
    HouseholdComparisonDetailTexts
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.texts';


export interface HouseholdComparisonRankRowConfig {
    rankingItem: HouseholdComparisonRankingDataEntry;
    motivationalMessage: string;
    showDots: boolean;
}


const emptyConfig: HouseholdComparisonRankRowConfig = {
    rankingItem: null,
    motivationalMessage: '',
    showDots: false
};


@Component({
    selector: 'app-household-comparison-rank-row',
    templateUrl: './household-comparison-rank-row.component.html',
    styleUrls: ['./household-comparison-rank-row.component.scss']
})
export class HouseholdComparisonRankRowComponent implements OnInit {
    readonly TEXTS = HouseholdComparisonDetailTexts;
    protected readonly valueUnit = 'kWh';

    @Input() config: HouseholdComparisonRankRowConfig = emptyConfig;

    constructor() {
    }


    ngOnInit(): void {
    }
}
