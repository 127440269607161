<div class="view-container">
    <h2 class="household-summary font-bold">
        <ng-container *ngIf="householdComparisonData$ | async as householdComparisonData">
            <ng-container *ngIf="householdComparisonData.viewState === ViewState.SUCCESS">
                <ng-container *ngIf="householdComparisonData.comparableHouseholds > 1">
                    <ng-container *ngIf="currentFilter$ | async as currentFilters">
                        <ng-container *ngIf="currentFilters.length === 0; else noFilter">
                            {{LABELS.DISPLAY_COMPARABLE_HOUSHOLDS_ALL}}
                        </ng-container>
                        <ng-template #noFilter>
                            {{householdComparisonData.comparableHouseholds}}
                            {{LABELS.DISPLAY_COMPARABLE_HOUSEHOLDS}}
                        </ng-template>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="householdComparisonData.comparableHouseholds === 1">
                    {{householdComparisonData.comparableHouseholds}}
                    {{LABELS.DISPLAY_COMPARABLE_HOUSEHOLD}}
                </ng-container>
                <ng-container *ngIf="householdComparisonData.comparableHouseholds === 0">
                    {{LABELS.DISPLAY_COMPARABLE_HOUSEHOLD_EMPTY}}
                </ng-container>
            </ng-container>
            <ng-container *ngIf="householdComparisonData.viewState === ViewState.LOADING">
                {{LABELS.STATE_LOADING}}
            </ng-container>
        </ng-container>
    </h2>

    <ng-container *ngIf="currentFilter$ | async as currentFilter">
        <ng-container *ngIf="currentFilter.length > 0; else filterEmptyState">
            <div class="filter-list scroll-container-vertical household-comparison">
                <div class="filter-list-item"
                     *ngFor="let item of currentFilter">
                    <div [style.mask-image]="determineFilterIcon(item.profileKey)"
                         [style.-webkit-mask-image]="determineFilterIcon(item.profileKey)">
                    </div>
                    <strong>
                        {{item.title}}
                    </strong>
                    {{item.wording}}
                </div>
            </div>
        </ng-container>
        <ng-template #filterEmptyState>
            <div class="filter-list empty">
                Keine Filter ausgewählt
            </div>
        </ng-template>
    </ng-container>

    <div class="button-wrap">
        <button class="iona-button small dark"
                (click)="onEnterFormMode()"
                [disabled]="application.isDemoMode()">
            {{LABELS.DISPLAY_BUTTON_ADJUST}}
        </button>
    </div>

    <p class="info small m-t-m m-b-n">
        <ng-container *ngIf="!application.isDemoMode; else demoModeHint">
            {{TEXTS.FILTER_DISPLAY.INFO}}
        </ng-container>
        <ng-template #demoModeHint>
            {{TEXTS.FILTER_DISPLAY.INFO_DEMO_MODE}}
        </ng-template>
    </p>

</div>
